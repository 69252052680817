<template>
  <div class="input-group">
    <input
      name="custom-calendar"
      type="text"
      readonly
      class="form-control text-right"
      :placeholder="$t('search-tab.choose-date')"
      @click="toggleCalendar"
      style="cursor: auto"
      :value="inputText"
      ref="inputControl"
      dir="ltr"
    />
    <div class="input-group-append">
      <span class="input-group-text">
        <i class="far fa-calendar-alt"></i>
      </span>
    </div>
    <div class="col-12 calendar-body" v-if="show" :class="{'up-open': type !== 'side' && enableSearchAgentState}">

      <b-tabs v-model="tabIndex" fill card v-if="isMultipleSearchCase && !isMultipleSearchDestinationCase">
        <b-tab v-for="(calendar, index) in maxCalendar"
          :key="index" :active="index===tabIndex">
          <template #title>
            <div class="tab-title"><p>{{ showSelectedDate(index) }}</p></div>
          </template>
          <b-card-text>
            <calendar
              :inx="index"
              :calendarShowed="calendarShow"
              :containerWidth="containerWidth"
              :type="type"
              :destination="destination"
              @dates="chosenDate"
              @dateSelected="oneDateChosen"/>
          </b-card-text>
        </b-tab>
      </b-tabs>

      <b-card v-else>
        <calendar
          :inx="0"
          :calendarShowed="calendarShow"
          :containerWidth="containerWidth"
          :type="type"
          :destination="destination"
          @dates="chosenDate"
          @dateSelected="oneDateChosen"/>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BTabs, BTab, BCard, BCardText } from 'bootstrap-vue';
import { formatTwoDate } from '@/utils/base64Util';

export default {
  name: 'date-picker-theme0',
  props: {
    calendarShow: {
      type: Boolean,
      default: false,
    },
    destination: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'tabs',
    },
  },
  components: {
    BTabs,
    BTab,
    BCard,
    BCardText,
    calendar: () => import('./DatepickerRange'),
  },
  data() {
    return {
      show: false,
      containerWidth: 0,
      tabIndex: 0,
      searchMultiDates: [],
      areDatesChosen: [],
    };
  },
  computed: {
    ...mapGetters({
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      currentPage: 'GET_CURRENT_PAGE',
      isEmalonMode: 'GET_IS_EMALON_MODE',
      isMultipleSearchCase: 'GET_IS_MULTIPLE_SEARCH_CASE',
      isMultipleSearchDestinationCase: 'GET_MULTI_SEARCH_DESTINATION_STATE',
    }),
    maxCalendar() {
      return (this.isMultipleSearchCase) ? 3 : 1;
    },
    enableSearchAgentState() {
      const { isOdysseySite, currentPage, isEmalonMode } = this;
      return ((isOdysseySite && currentPage !== 'search-result') || isEmalonMode);
    },
    inputText() {
      const { searchMultiDates } = this;
      if (searchMultiDates.length === 0) {
        return '';
      } else {
        // return searchMultiDates.filter((d) => d.from && d.to).map((d) => (formatTwoDate(d.from, d.to))).join(' ~ ');
        return searchMultiDates[0].from && searchMultiDates[0].to ? formatTwoDate(searchMultiDates[0].from, searchMultiDates[0].to) : '';
      }
    },
  },
  watch: {
    calendarShow() {
      this.show = this.calendarShow;
    },
  },
  created() {
    window.addEventListener('resize', this.refreshContainerSize);
  },
  mounted() {
    this.refreshContainerSize();
  },
  destroyed() {
    window.removeEventListener('resize', this.refreshContainerSize);
  },
  methods: {
    toggleCalendar() {
      this.show = !this.show;
      if (this.show) {
        // this.mode = 'startSelect';
      }
      this.refreshContainerSize();
      // this.setStartDate();
    },
    refreshContainerSize() {
      const width = this.$refs.inputControl.clientWidth;
      this.containerWidth = width < 250 ? 250 : width;
    },
    chosenDate(emittingDate) {
      const indexNum = emittingDate.dateIndex;
      const old = [...this.searchMultiDates];

      if (!old[indexNum]) {
        old[indexNum] = {
          from: '',
          to: '',
        };
      }

      old[indexNum].from = emittingDate.from;
      old[indexNum].to = emittingDate.to;

      this.searchMultiDates = [...old];
      this.$emit('dates', this.searchMultiDates);
    },
    oneDateChosen(howTo) {
      const indexNum = howTo.dateIndex;
      this.areDatesChosen[indexNum] = howTo.isChosen;
      // if (indexNum === (this.maxCalendar - 1)) {
      this.$emit('finalDateSelect', true);
      this.show = false;
      // } else if (indexNum < (this.maxCalendar - 1) && howTo.isChosen) {
      //   this.tabIndex += 1;
      // }
    },
    showSelectedDate(inx) {
      if (this.searchMultiDates.length === 0) return inx;
      const from = this.searchMultiDates[inx]?.from,
        to = this.searchMultiDates[inx]?.to;
      return from && to ? formatTwoDate(from, to) : inx;
    },
  },
};
</script>
<style scoped>
.input-group {
  direction: rtl !important;
}
.calendar-body {
  z-index: 1;
}
.tab-pane.card-body, .card-body {
  padding: 0;
}
.tabs {
  background-color: white;
}
</style>
<style>
.filter-tab .calendar-body .nav-tabs .nav-link {
  display: block;
}
</style>
